<template>
  <div class="d-flex flex-column align-center my-5">
    <v-tabs v-model="tab" centered show-arrows>
      <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
      <v-tab>All</v-tab>
      <v-tab v-for="(item, index) in allCategories" :key="index">{{
        item
      }}</v-tab>
    </v-tabs>
    <v-progress-linear indeterminate v-if="loading" />
    <div class="events-content-section">
      <div
        v-for="event in events"
        :key="event._id"
        style="width: 50%; padding: 10px;"
      >
        <v-hover v-slot="{ hover }">
          <div @click="goToEventDetail(event._id)" class="mb-5">
            <div style="position: relative;">
              <v-img
                :src="event.cover"
                :class="{ zoom: !hover, triggerzoom: hover }"
                height="200px"
              >
                <div style="position: absolute; bottom: 10px; right: 10px;">
                  <v-img
                    src="@/assets/events/rect_date_outline.png"
                    width="80"
                    height="80"
                    contain
                  >
                    <div
                      class="d-flex flex-column align-center; justify-center;"
                      style="width: 100%;"
                    >
                      <div
                        style="color: #7024c4; font-family: 'Poppins-SemiBold'; font-size: 30px; text-align: center; margin-top: 4px;"
                      >
                        {{ getDate(event.createdAt) }}
                      </div>
                      <div
                        style="color: #7024c4; font-family: 'Poppins-SemiBold'; font-size: 14px; text-align: center; margin-top: 3px;"
                      >
                        {{ getMonth(event.createdAt) }}
                      </div>
                    </div>
                  </v-img>
                </div>
              </v-img>
            </div>
            <div
              style="font-size: 20px; color: #1c1c1a; margin-top: 10px;"
              class="url-button"
              @click="goToEventDetail(event._id)"
            >
              {{ event.title }}
            </div>
          </div>
        </v-hover>
      </div>
      <div
        v-if="!events.length"
        style="height: 300px"
        class="d-flex justify-center align-center flex-column"
      >
        <div
          style="color: #482684; font-family: 'Poppins-SemiBold'; font-size: 20px"
        >
          No events
        </div>
        <div
          style="color: #423f63; font-family: 'Poppins-Regular'; margin-top: 10px;"
        >
          There are currenly no events available.
        </div>
      </div>
    </div>
    <div>
      <v-pagination v-model="page" :length="totalPage" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
export default {
  data() {
    return {
      page: 1,
      tab: 0,
      events: [],
      loading: false,
      totalPage: 1,
      date: "",
      allCategories: [
        "Yoga classes",
        "Diabetes prevention",
        "Running groups",
        "Healthy eating",
        "Healthy recipes",
        "Exercise videos",
        "Community events",
        "Volunteering",
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchAllEvents: "event/fetchAllEvents",
      fetchUserEvents: "event/fetchUserEvents",
      fetchAllEventsCount: "event/fetchAllEventsCount",
      fetchUserEventsCount: "event/fetchUserEventsCount",
      fetchAllCategories: "variable/fetchAllCategories",
    }),
    loadEvents() {
      this.loadPages();
      this.loading = true;
      var params = { limit: 10, page: this.page - 1 };
      if (this.tab) {
        params.category = this.allCategories[this.tab - 1];
      }
      if (this.keyword) {
        params.keyword = this.keyword;
      }
      if (this.date) {
        params.date = this.date;
      }
      if (this.profileType == "User") {
        this.fetchUserEvents(params)
          .then((res) => {
            this.events = res;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
          });
      } else {
        this.fetchAllEvents(params)
          .then((res) => {
            this.events = res;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            console.log(error.response.data.message);
          });
      }
    },
    loadPages() {
      var params = { limit: 10, page: this.page - 1 };
      if (this.tab) {
        params.category = this.allCategories[this.tab - 1];
      }
      if (this.keyword) {
        params.keyword = this.keyword;
      }
      if (this.date) {
        params.date = this.date + " EDT";
      }

      if (this.profileType == "User") {
        this.fetchUserEventsCount(params)
          .then((res) => {
            console.log(res);
            this.totalPage = Math.floor(res / 10) + 1;
            console.log(this.totalPage);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      } else {
        this.fetchAllEventsCount(params)
          .then((res) => {
            this.totalPage = Math.floor(res / 10) + 1;
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },
    extractContent(s) {
      var span = document.createElement("span");
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
    convertTZ(date) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        )
      );
    },
    getDate(dateStr) {
      return dateFormat(this.convertTZ(dateStr), "d");
    },
    getMonth(dateStr) {
      return dateFormat(this.convertTZ(dateStr), "mmmm");
    },
    goToEventDetail(_id) {
      this.$router.push({ name: "event-details", query: { _id } });
    },
    selectedTab() {
      if (!this.$route.query.category) {
        this.tab = 0;
      } else {
        for (var i = 0; i < this.allCategories.length; i++) {
          if (this.allCategories[i] == this.$route.query.category) {
            this.tab = i + 1;
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
    keyword: {
      get() {
        return this.$store.state.auth.keyword;
      },
      set(value) {
        this.$store.commit("auth/setKeyword", value);
      },
    },
  },
  watch: {
    tab() {
      if (this.page == 1) {
        this.loadEvents();
      } else {
        this.page = 1;
      }
    },
    page(newValue) {
      if (newValue) {
        this.loadEvents();
      }
    },
    keyword() {
      this.loadEvents();
    },
    "$route.query.date": function(newValue) {
      this.date = newValue;
      this.loadEvents();
    },
    profile(newValue) {
      if (newValue && this.profileType == "User" && newValue.interests.length) {
        this.allCategories = newValue.interests;
        this.selectedTab();
      }
    },
  },
  mounted() {
    this.keyword = this.$route.query.key;
    this.date = this.$route.query.date;
    this.loadEvents();
    this.loading = true;
    this.fetchAllCategories()
      .then((res) => {
        this.loading = false;
        if (this.profileType == "User" && this.profile.interests.length) {
          this.allCategories = this.profile.interests;
        } else {
          this.allCategories = res.map((c) => c.title);
        }
        this.selectedTab();
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style scoped>
.events-content-section {
  max-width: 800px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
.zoom >>> .v-image__image {
  transition: transform 0.5s; /* Animation */
}

.triggerzoom >>> .v-image__image {
  transition-duration: 0.5s;
  transform: scale(
    1.2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media only screen and (max-width: 600px) {
  .events-content-section {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .body-content {
    max-width: 100%;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .body-content {
    max-width: 100%;
    margin-top: 50px;
  }
}
@media only screen and (min-width: 992px) {
  .body-content {
    max-width: 100%;
    margin-top: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .body-content {
    max-width: 1000px;
    margin-top: 50px;
  }
}
</style>
