<template>
  <div>
    <div
      style="width: 200px; margin-left: 50px; margin-right: 50px; margin-top: 20px;"
    >
      <v-menu
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        :return-value.sync="searchDate"
        transition="scale-transition"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchDateStr"
            v-bind="attrs"
            v-on="on"
            label="Date"
            clearable
            dense
            hide-details
            @click:clear="onClearDate"
          />
        </template>
        <v-date-picker v-model="searchDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateMenu = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="onSaveDate">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <event-list-section />
  </div>
</template>
<script>
import EventListSection from "./EventListSection.vue";
import dateFormat from "dateformat";

export default {
  components: { EventListSection },
  data() {
    return {
      dateMenu: false,
      searchDate: null,
      searchDateStr: "",
    };
  },
  methods: {
    onSaveDate() {
      if (this.searchDateStr != this.searchDate) {
        var date = new Date(this.searchDate + " EST");
        this.searchDateStr = dateFormat(date, "mm/dd/yyyy");
        this.dateMenu = false;
        this.onSearchDate();
      }
    },
    onSearchDate() {
      if (this.searchDateStr) {
        this.$router.replace({
          name: "event-search",
          query: { ...this.$route.query, date: this.searchDateStr },
        });
      } else {
        this.$router.replace({
          name: "event-search",
          query: { ...this.$route.query, date: "" },
        });
      }
    },
    onClearDate() {
      this.$router.replace({
        name: "event-search",
        query: { ...this.$route.query, date: "" },
      });
    },
  },
  computed: {
    keyword: {
      get() {
        return this.$store.state.auth.keyword;
      },
      set(value) {
        this.$store.commit("auth/setKeyword", value);
      },
    },
  },
  watch: {
    "$route.query.key": function(newValue) {
      this.keyword = newValue;
    },
    "$route.query.date": function(newValue) {
      this.searchDateStr = newValue;
    },
  },
  mounted() {
    var date = new Date(this.$route.query.date + " EST");
    this.keyword = this.$route.query.key;
    this.searchDate = dateFormat(date, "yyyy-mm-dd");
    this.searchDateStr = this.$route.query.date;
  },
};
</script>
<style scoped>
div.v-date-picker-table >>> table > tbody > tr > td {
  padding: 0 !important;
}
</style>
